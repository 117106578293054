import { Subject, BehaviorSubject } from 'rxjs';
import jwtDecode from 'jwt-decode';

export const Identity$ = new BehaviorSubject<any>(getIdentity());

export function getIdentity() {
	let token = localStorage.getItem('jwt');

	if (token) {
		let tokenData: any = jwtDecode(token);
		return tokenData.identity;
	} else {
		return null;
	}
}

export function refreshIdentityFromToken() {
	Identity$.next(getIdentity());
}

export function checkRolePermissions(pattern: string) {
	let identity: any = getIdentity();

	if (identity) {
		for (let role of identity.roles) {
			let re = new RegExp(pattern.replace(/\*/gim, `.*`).replace(/\./gim, `\.`) + '$');
			let re2 = new RegExp('^' + pattern.replace(/\*/gim, `.*`).replace(/\./gim, `\.`));

			if (re.test(role.name) || re2.test(role.name)) {
				return true;
			}
		}
	}

	return false;
}
